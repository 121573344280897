<template>
  <teleport to="body">
    <div class="alert-modal" @click="cancelFunc">
      <div class="alert-modal__container" @click.stop>
        <div class="top-alert-icon" v-if="alertIcon">
          <div class="top-alert-icon__container">
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14 19.75C14.3258 19.75 14.599 19.6398 14.8194 19.4194C15.0398 19.199 15.15 18.9258 15.15 18.6C15.15 18.2742 15.0398 18.001 14.8194 17.7806C14.599 17.5602 14.3258 17.45 14 17.45C13.6742 17.45 13.401 17.5602 13.1806 17.7806C12.9602 18.001 12.85 18.2742 12.85 18.6C12.85 18.9258 12.9602 19.199 13.1806 19.4194C13.401 19.6398 13.6742 19.75 14 19.75ZM12.85 15.15H15.15V8.25H12.85V15.15ZM14 25.5C12.4092 25.5 10.9142 25.1981 9.515 24.5944C8.11583 23.9906 6.89875 23.1713 5.86375 22.1362C4.82875 21.1012 4.00937 19.8842 3.40563 18.485C2.80187 17.0858 2.5 15.5908 2.5 14C2.5 12.4092 2.80187 10.9142 3.40563 9.515C4.00937 8.11583 4.82875 6.89875 5.86375 5.86375C6.89875 4.82875 8.11583 4.00937 9.515 3.40563C10.9142 2.80187 12.4092 2.5 14 2.5C15.5908 2.5 17.0858 2.80187 18.485 3.40563C19.8842 4.00937 21.1012 4.82875 22.1362 5.86375C23.1713 6.89875 23.9906 8.11583 24.5944 9.515C25.1981 10.9142 25.5 12.4092 25.5 14C25.5 15.5908 25.1981 17.0858 24.5944 18.485C23.9906 19.8842 23.1713 21.1012 22.1362 22.1362C21.1012 23.1713 19.8842 23.9906 18.485 24.5944C17.0858 25.1981 15.5908 25.5 14 25.5ZM14 23.2C16.5683 23.2 18.7437 22.3088 20.5263 20.5263C22.3088 18.7437 23.2 16.5683 23.2 14C23.2 11.4317 22.3088 9.25625 20.5263 7.47375C18.7437 5.69125 16.5683 4.8 14 4.8C11.4317 4.8 9.25625 5.69125 7.47375 7.47375C5.69125 9.25625 4.8 11.4317 4.8 14C4.8 16.5683 5.69125 18.7437 7.47375 20.5263C9.25625 22.3088 11.4317 23.2 14 23.2Z" fill="#FC8812"/>
            </svg>
          </div>
        </div>
        <div class="alert-description" :class="{'alert-description-central':centralText}" v-if="title||description">
          <div class="alert-description__header" v-if="title">{{title}}</div>
          <div class="alert-description__body" v-if="description">{{description}}</div>
        </div>
        <div class="alert-btns">
          <div class="alert-btns__container">
            <div class="alert-btns__container_el" v-if="visibleCancelBottomButton">
              <default-button
                :size="'medium'"
                :type="'secondary'"
                @click-element="cancelFunc"
              >{{$t('Отмена')}}</default-button>
            </div>
            <div class="alert-btns__container_el" @click="confirmFunc" v-if="confirmText">
              <default-button
                  :size="'medium'"
                  :type="'secondary'"
                  :appearance="'negative'"
                  @click-element="confirmFunc"
              >{{$t(confirmText)}}</default-button>
            </div>
          </div>
        </div>
        <div class="alert-icon" @click="cancelFunc">
          <div class="alert-icon__container">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.275 18L6 16.725L10.725 12L6 7.275L7.275 6L12 10.725L16.725 6L18 7.275L13.275 12L18 16.725L16.725 18L12 13.275L7.275 18Z" fill="var(--colorIconPrimary)"/>
            </svg>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import DefaultButton from "@/components/redesign/defaultButton.vue";
import Teleport from "@/components/redesign/teleport.vue";

export default {
  name: "alertModal",
  components: {Teleport,DefaultButton},
  props:{
    title:{
      type:String,
      default:''
    },
    description:{
      type:String,
      default:''
    },
    confirmText:{
      type:String,
      default:'Подтвердить'
    },
    visibleCancelBottomButton:{
      type:Boolean,
      default:true
    },
    centralText:{
      type:Boolean,
      default:false
    },
    alertIcon:{
      type:Boolean,
      default:false
    },
  },
  methods:{
    cancelFunc(){
      this.$emit('cancel-alert')
    },
    confirmFunc(){
      this.$emit('confirm-alert')
    }
  }
}
</script>

<style lang="scss" scoped>
.alert-modal{
  position: fixed;
  z-index: 7000;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(23, 23, 28, 0.40);
  display: flex;
  overflow: auto;
  .alert-modal__container{
    width: 100%;
    max-width: 480px;
    margin: auto;
    border-radius: 12px;
    background: var(--colorBackgroundContent);
    box-shadow: 0px 16px 32px 0px rgba(23, 23, 28, 0.10), 0px 0px 16px 0px rgba(23, 23, 28, 0.15);
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 16px;
    padding: 24px 16px 16px 16px;
    .top-alert-icon{
      display: flex;
      justify-content: center;
      .top-alert-icon__container{
        min-height: 28px;
        max-height: 28px;
        min-width: 28px;
        max-width: 28px;
        svg{
          display: block;
          width: 100%;
          height: 100%;
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
    .alert-description{
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding: 0 8px;
      .alert-description__header{
        color: var(--colorTextPrimary);
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 120% */
      }
      .alert-description__body{
        color: var(--colorTextSecondary);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 125% */
        letter-spacing: 0.08px;
      }
      &.alert-description-central{
        text-align: center;
      }
    }
    .alert-btns{
      .alert-btns__container{
        display: flex;
        justify-content: flex-end;
        gap: 8px;
      }
    }
    .alert-icon{
      position: absolute;
      top:0;
      right: 0;
      padding: 12px;
      cursor: pointer;
      .alert-icon__container{
        min-height: 24px;
        max-height: 24px;
        min-width: 24px;
        max-width: 24px;
        svg{
          display: block;
          width: 100%;
          height: 100%;
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 1365px){
  .alert-modal{
    .alert-modal__container{
      .alert-icon{
        display: none;
      }
    }
  }
}
@media screen and (max-width: 743px){
  .alert-modal{
    .alert-modal__container{
      max-width: 296px;
      .alert-description{
        text-align: center;
      }
      .alert-btns{
        .alert-btns__container{
          .alert-btns__container_el{
            width: 100%;
          }
        }
      }
    }
  }
}
</style>