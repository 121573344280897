<template>
  <div class="default-list-dropdown" :class="{'error-wrapper':notificationError, 'only-mobile-view':onlyMobileView}">
    <div
        class="default-list-dropdown__select"
        :class="{
      'active':show,
      'tag-mode-wrapper':tagMode&&Object.values(values).length
    }"
        @click="changeVisible"
    >
      <template v-if="maskIcons">
        <template v-if="icons?.length||defaultIcon">
          <div class="icon-desktop">
            <icon-component
                :icon="icons?.length?icons[0]:defaultIcon"
                :color="'var(--colorIconAccentExtra)'"
                :size="20"
            />
          </div>
          <div class="icon-mobile">
            <icon-component
                :icon="icons?.length?icons[0]:defaultIcon"
                :color="'var(--colorIconAccentExtra)'"
                :size="24"
            />
          </div>
        </template>
      </template>
      <template v-else>
        <div class="image-selected-wrapper" v-if="icons?.length||defaultIcon">
          <img class="image-selected-element" :src="icons?.length?icons[0]:defaultIcon" alt=""/>
        </div>
      </template>
      <div class="tags-container" v-if="tagMode">
        <template v-if="Object.values(values).length">
          <div class="tag-el" v-for="(tag,index) in Object.values(values)">
            <div class="tag-el__value">{{$i18n.t(tag)}}</div>
            <div class="tag-el__icon" @click.stop="deleteByIndex(index)">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.85 12L4 11.15L7.15 8L4 4.85L4.85 4L8 7.15L11.15 4L12 4.85L8.85 8L12 11.15L11.15 12L8 8.85L4.85 12Z" fill="var(--colorIconSecondary)"/>
              </svg>
            </div>
          </div>
        </template>
        <template v-else>{{noneText?noneText:$t('НеВыбрано')}}</template>
      </div>
      <div
          class="default-list-dropdown__select_title"
          v-else
      >
        <span class="selected-value" v-if="Object.values(values).length > 0">
          {{Object.values(values).length > 1 ?
            ($t('Выбрано')+' '+Object.values(values).length+' '+$t('зн')) :
            Object.values(values).map(el => this.$i18n.t(el)).join(', ')}}
        </span>
        <span v-else>{{noneText?noneText:$t('НеВыбрано')}}</span>
      </div>
      <div class="del-selected" @click.stop="selectValue(null)" v-if="deleteAllIcon&&Object.values(values).length">
        <icon-component
            :icon="'/default-icons/close.svg'"
            :color="'var(--colorIconSecondary)'"
            :size="16"
        />
      </div>
      <div class="expand-more-icon icon-desktop" :class="{'active':show}">
        <icon-component
            :icon="'/default-icons/expand_more.svg'"
            :color="'var(--colorIconSecondary)'"
            :size="20"
        />
      </div>
      <div class="expand-more-icon icon-mobile" :class="{'active':show}">
        <icon-component
            :icon="'/default-icons/expand_more.svg'"
            :color="'var(--colorIconSecondary)'"
            :size="24"
        />
      </div>
    </div>
    <div class="default-list-dropdown__options" v-if="show" v-on-clickaway="closeSelect">
      <div class="default-list-dropdown__options_search" v-if="searchVisible">
        <div class="search-container">
          <div class="dropdown-active-search-icon">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15.9375 17L10.9583 12.0208C10.5417 12.3264 10.0848 12.566 9.58767 12.7396C9.09056 12.9132 8.56158 13 8.00073 13C6.61135 13 5.43056 12.5139 4.45833 11.5417C3.48611 10.5694 3 9.38889 3 8C3 6.61111 3.48611 5.43056 4.45833 4.45833C5.43056 3.48611 6.61111 3 8 3C9.38889 3 10.5694 3.48611 11.5417 4.45833C12.5139 5.43056 13 6.61135 13 8.00073C13 8.56158 12.9132 9.09056 12.7396 9.58767C12.566 10.0848 12.3264 10.5417 12.0208 10.9583L17 15.9375L15.9375 17ZM8 11.5C8.97222 11.5 9.79861 11.1597 10.4792 10.4792C11.1597 9.79861 11.5 8.97222 11.5 8C11.5 7.02778 11.1597 6.20139 10.4792 5.52083C9.79861 4.84028 8.97222 4.5 8 4.5C7.02778 4.5 6.20139 4.84028 5.52083 5.52083C4.84028 6.20139 4.5 7.02778 4.5 8C4.5 8.97222 4.84028 9.79861 5.52083 10.4792C6.20139 11.1597 7.02778 11.5 8 11.5Z" fill="var(--colorIconTertiary)"/>
            </svg>
          </div>
          <div class="dropdown-active-search-input">
            <input type="text" :placeholder="$t('Поиск')" v-model="searchQuery" ref="myInput">
          </div>
        </div>
      </div>
      <div class="default-list-dropdown__options_list">
        <div class="wrapper-list-container">
          <div
              class="options-element"
              :class="{'active':values.indexOf(noneText) !== -1}"
              v-if="!noneHide"
              @click.stop="selectValue(null)"
          >{{ noneText }}</div>
          <template v-for="element in sortedList">
            <div
                class="options-element"
                :class="{'active':ids.indexOf(element.id) !== -1}"
                :style="{backgroundColor: element.bg, color: element.color}"
                @click.stop="selectValues(element.id, element.text)"
                v-if="element.id !== undefined && element.color !== undefined"
                :key="element.id"
            >
              <div class="options-element__text">{{ $i18n.t(element.text) }}</div>
              <div class="options-element__icon">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.22918 14.0625L4.70834 10.5208L5.75001 9.47917L8.22918 11.9375L14.25 5.9375L15.2917 7L8.22918 14.0625Z" fill="var(--colorIconAccent)"/>
                </svg>
              </div>
            </div>
            <div
                class="options-element"
                :class="{'active':ids.indexOf(element.id) !== -1}"
                @click.stop="selectValues(element.id, element.text, element.icon)"
                v-else-if="element.id !== undefined"
                :key="element.id"
            >
              <template v-if="maskIcons">
                <div v-if="element.icon" class="icon-param"  :style="`mask: url(${element.icon}) no-repeat center / contain;`"></div>
              </template>
              <template v-else>
                <div class="image-option-wrapper" v-if="element.icon">
                  <img class="image-option-element" :src="element.icon" alt=""/>
                </div>
              </template>
              <div class="options-element__text">{{ $i18n.t(element.text) }}</div>
              <div class="options-element__icon">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.22918 14.0625L4.70834 10.5208L5.75001 9.47917L8.22918 11.9375L14.25 5.9375L15.2917 7L8.22918 14.0625Z" fill="var(--colorIconAccent)"/>
                </svg>
              </div>
            </div>
            <div
                class="options-element"
                :class="{'active':values.indexOf(element) !== -1}"
                @click.stop="selectValue(element)"
                v-else
                :key="element">
              <template v-if="maskIcons">
                <div v-if="element.icon" class="icon-param"  :style="`mask: url(${element.icon}) no-repeat center / contain;`"></div>
              </template>
              <template v-else>
                <div class="image-option-wrapper" v-if="element.icon">
                  <img class="image-option-element" :src="element.icon" alt=""/>
                </div>
              </template>
              <div class="options-element__text">{{ $i18n.t(element) }}</div>
              <div class="options-element__icon">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.22918 14.0625L4.70834 10.5208L5.75001 9.47917L8.22918 11.9375L14.25 5.9375L15.2917 7L8.22918 14.0625Z" fill="var(--colorIconAccent)"/>
                </svg>
              </div>
            </div>
          </template>
          <template v-for="group in SortedGroupList">
            <div class="dropdown-item-group" :key="group.title">{{ group.title }}</div>
            <template v-for="el in group.array">
              <div
                  class="dropdown-padding options-element"
                  :class="{'active':ids.indexOf(el.id) !== -1}"
                  :style="{backgroundColor: el.bg}" @click.stop="selectValues(el.id, el.name)"
                  v-if="el.id !== undefined && el.bg !== undefined"
                  :key="el.id"
              >{{ $i18n.t(el.name) }}</div>
              <div
                  class="dropdown-padding options-element"
                  :class="{'active':ids.indexOf(el.id) !== -1}"
                  @click.stop="selectValues(el.id, group.title+': '+el.name)"
                  v-else-if="el.id !== undefined"
                  :key="el.id"
              >{{ $i18n.t(el.name) }}</div>
            </template>
          </template>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import Vue from 'vue'
import IconComponent from "@/components/redesign/iconComponent.vue";
export default {
  name: "defaultNewDropdown",
  components: {IconComponent},
  props:  {
    elements:{},
    value:{},
    multiple:{},
    open:{},
    none:{},
    noneHide:{},
    groups:{},
    multipleSelect:{},
    notificationError:{},
    searchVisible:{
      type:Boolean,
      default:true
    },
    onlyMobileView:{
      type:Boolean,
      default:false
    },
    defaultIcon:{
      type:String,
      default:''
    },
    tagMode:{
      type:Boolean,
      default:false
    },
    deleteAllIcon:{
      type:Boolean,
      default:false
    },
    maskIcons:{
      type:Boolean,
      default:true
    },
  },
  data () {
    return {
      searchQuery:'',
      show: false,
      values: [],
      ids: [],
      icons:[],
      noneText: this.$i18n.t('НеВажно')
    }
  },
  mounted () {
    this.initialization()
  },
  methods: {
    closeSelect(){
      this.show=false
    },
    initialization(){
      if (this.none) this.noneText = this.none
      if (this.multiple === false && this.value !== null) {
        if(this.elements !== undefined) {
          if (typeof this.elements[0] === 'object') {
            let obj = this.elements.find(o => o.id === this.value)
            if (obj) {
              if(obj.icon) this.icons =[obj.icon]
              this.values = [obj.text]
              this.ids = [obj.id]
            }
          } else if(typeof this.value === 'object'){
            this.values = [...this.value]
          }
          else {
            this.values = [this.value]
          }
        }

        if(this.groups !== undefined) {
          if (typeof this.groups[0] === 'object') {
            this.groups.forEach(group => {
              let obj = group.array.find(o => o.id === this.value)
              if (obj) {
                this.values = [group.title+': '+obj.name]
                this.ids = [obj.id]
              }
            })
          } else {
            this.values = [this.value]
          }
        }
      }
      else if (this.multiple === true && this.value !== null) {
        if(this.elements !== undefined) {
          if(typeof this.elements[0] === 'object' && (typeof this.value[0]==='object'|| typeof this.value[0]==='string')) {
            this.value.forEach(val => {
              let obj = this.elements.find(o => o.id === val)
              if(obj) {
                this.values.push(obj.text)
                this.ids.push(obj.id)
              }
            })
          } else {
            if(typeof this.elements[0] === 'object' && typeof this.value[0]==='number') {
              this.value.forEach(val => {
                let obj = this.elements.find(o => o.id === val)
                if(obj) {
                  this.values.push(obj.text)
                  this.ids.push(obj.id)
                }
              })
            } else {
              if(this.value!==undefined) {
                this.value.forEach(val => {
                  let obj = this.elements.find(o => o === val)
                  if (obj||obj===0) {
                    this.values.push(obj)
                    this.ids.push(obj)
                  }
                })
              }
            }
          }
        }

        if(this.groups !== undefined) {
          if(typeof this.groups[0] === 'object' && (typeof this.value[0]==='object'|| typeof this.value[0]==='string')) {
            this.value.forEach(val => {
              this.groups.forEach(group => {
                let obj = group.array.find(o => o.id === val)
                if(obj) {
                  this.values.push(group.title+': '+obj.name)
                  this.ids.push(obj.id)
                }
              })
            })
          } else {
            if(typeof this.groups[0] === 'object' && typeof this.value[0]==='number') {
              this.value.forEach(val => {
                this.groups.forEach(group => {
                  let obj = group.array.find(o => o.id === val)
                  if(obj) {
                    this.values.push(group.title+': '+obj.name)
                    this.ids.push(obj.id)
                  }
                })
              })
            } else {
              if(this.value!==undefined) {
                this.value.forEach(val => {
                  this.groups.forEach(group => {
                    let obj = group.array.find(o => o === val)
                    if (obj ||obj===0) {
                      this.values.push(obj)
                      this.ids.push(obj)
                    }
                  })
                })
              }
            }
          }
        }
      }
    },
    changeVisible(){
      this.show=!this.show
      if(this.show)this.$emit('open-options')
    },
    checkClick () {
      this.searchQuery=''
      this.show = false
    },
    deleteByIndex(index) {
      if (this.ids?.length) Vue.delete(this.ids, index)
      Vue.delete(this.values, index)
      if (this.multiple === false) {
        this.$emit('input', this.values);
        this.$emit('selected', this.values);
      } else {
        if (this.ids?.length) {
          this.$emit('input', this.ids);
          this.$emit('selected', this.ids);
        } else {
          this.$emit('input', this.values);
          this.$emit('selected', this.values);
        }
      }
    },

    selectValues (id, text, icon) {
      if(!this.multiple) this.show=false
      this.searchQuery=''
      //this.show = false
      if (this.multiple === false) {

        if (text === this.noneText || text === null) {
          this.values = []
          this.ids = []
          this.icons=[]
          this.$emit('input', null);
          this.$emit('selected',  null);
        }
        else {
          this.values = [text]
          this.ids = [id]
          if(icon)this.icons=[icon]
          else this.icons=[]
          this.$emit('input', this.ids[0]);
          this.$emit('selected',  this.ids[0]);
        }
      } else {
        if (text === this.noneText) {
          this.values = []
          this.ids = []
     /*     this.icons=[]*/
          this.$emit('input', null);
          this.$emit('selected',  null);
        } else {
          /*if (this.values.indexOf(text) !== -1) {
            var key = this.values.indexOf(text)
            Vue.delete(this.values, key)
          } else {
            this.values.push(text)
          }*/
          if (this.ids.indexOf(id) !== -1) {
          /*  if(icon){
              var keyc = this.icons.indexOf(icon)
              Vue.delete(this.icons, keyc)
            } else this.icons=[]*/
            var keyd = this.ids.indexOf(id)
            Vue.delete(this.ids, keyd)
            var key = this.values.indexOf(text)
            Vue.delete(this.values, key)
          } else {
          /*  if(icon)this.icons.push(icon)
            else this.icons=[]*/
            this.ids.push(id)
            this.values.push(text)
          }
          this.$emit('input', this.ids);
          this.$emit('selected',  this.ids);
        }
      }
    },
    selectValue (val) {
      if(!this.multipleSelect) this.show = false
      if (this.multiple === false) {
        if (val === this.noneText || val === null) this.values = []
        else this.values = [val]
        this.$emit('input', this.values);
        this.$emit('selected', this.values);
      } else {
        if (val === this.noneText || val === null) {
          this.values = []
          this.$emit('input', null);
          this.$emit('selected', null);
        } else {
          if (this.values.indexOf(val) !== -1) {
            var key = this.values.indexOf(val)
            Vue.delete(this.values, key)
          } else {
            this.values.push(val)
          }
          this.$emit('input', this.values);
          this.$emit('selected', this.values);
        }
      }
    }
  },
  computed: {
    sortedList (){
      if(this.elements && this.elements[0]) {
        if (typeof this.elements[0] === 'object') {
          return [...this.elements].filter(post => post.text.toLowerCase().includes(this.searchQuery.toLowerCase()))
        } else return [...this.elements].filter(post => String(post).toLowerCase().includes(this.searchQuery.toLowerCase()))
      } else return this.elements
    },
    SortedGroupList(){
      const newGroups=[]
      if(this.groups && this.groups[0]) {
        if (typeof this.groups[0] === 'object') {
          for(let i=0;i<this.groups.length;i++){
            let mas=[]
            mas=[...this.groups[i].array].filter(post => post.name.toLowerCase().includes(this.searchQuery.toLowerCase()))
            newGroups.push({'title':this.groups[i].title, 'array':mas})
          }

          return newGroups.filter(grp=>grp.array.length!==0)
        }
      } else return this.groups
    }
  },
  watch:{
    value(newValue){
      this.values= []
      this.ids= []
      this.initialization()
    },
    elements() {
      this.values= []
      this.ids= []
      this.initialization()
    }
  }
}
</script>

<style lang="scss" scoped>
@mixin mobileView(){
  .default-list-dropdown__select{
    padding: 14px 16px;
    &.tag-mode-wrapper{
      padding: 8px 16px 8px 8px;
    }
    .tags-container{
      .tag-el{
        .tag-el__value{
          font-size: 14px;
          font-weight: 400;
          line-height: 18px; /* 128.571% */
          letter-spacing: 0.035px;
        }
      }
    }
    .default-list-dropdown__select_title{
      font-size: 16px;
      line-height: 20px; /* 128.571% */
    }
    .icon-desktop{
      display: none;
    }
    .icon-mobile{
      display: block;
    }
    .image-selected-wrapper{
      min-width: 24px;
      max-width: 24px;
      min-height: 24px;
      max-height: 24px;
      width: 24px;
      height: 24px;
    }
  }
}
.icon-param{
  background-color: var(--colorIconAccentExtra);
  width: 24px;
  height: 24px;
}
.dropdown-item-group{
  display: block;
  width: 100%;
  padding: 0.65rem 1rem;
  clear: both;
  color: var(--colorTextPrimary);
  text-align: inherit;
  text-decoration: none;
  background-color: transparent;
  border: 0;
  font-weight: bold
}
.default-list-dropdown{
  min-width: 150px;
  cursor: pointer;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  &.error-wrapper{
    .default-list-dropdown__select{
      border: 0.5px solid var(--colorStrokeNegative);
      background: var(--colorBackgroundNegativeTint);
      &:hover{
        background: var(--colorBackgroundNegativeTintHover);
      }
      &.active{
        border: 0.5px solid var(--colorStrokeNegative);
        background: var(--colorComponentFieldBackgroundActive);
        &:hover{
          background: var(--colorComponentFieldBackgroundActive);
        }
      }
    }
  }
  &.only-mobile-view{
    @include mobileView;
  }
  .default-list-dropdown__select{
    width: 100%;
    display: flex;
    padding: 8px 12px;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    border-radius: 8px;
    background-color: var(--colorComponentFieldBackground);
    border: 0.5px solid var(--colorComponentFieldStroke);
    transition: background 0.3s, border 0.3s;
    &:hover{
      background-color: var(--colorComponentFieldBackgroundHover);
    }
    &.tag-mode-wrapper{
      padding: 4px 12px 4px 4px;
    }
    &.active{
      border: 0.5px solid var(--colorStrokeAccentExtraInvariably);
      background: var(--colorComponentFieldBackgroundActive);
    }
    .icon-mobile{
      display: none;
    }
    .tags-container{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 4px;
      .tag-el{
        display: flex;
        align-items: center;
        gap: 4px;

        border-radius: 6px;
        border: 0.5px solid var(--colorStrokePrimary);
        background: var(--colorBackgroundContent);
        padding: 6px 6px 6px 8px;
        overflow: hidden;
        .tag-el__value{

          color: var(--colorTextPrimary);
          text-align: center;

          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 14px; /* 116.667% */
          letter-spacing: 0.03px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .tag-el__icon{
          cursor: pointer;
          min-height: 16px;
          max-height: 16px;
          min-width: 16px;
          max-width: 16px;
          svg{
            display: block;
            width: 100%;
            height: 100%;
            max-width: 100%;
            max-height: 100%;
          }
        }
      }
    }
    .default-list-dropdown__select_title,.tags-container{
      overflow: hidden;
      color: var(--colorTextSecondary);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 128.571% */
      letter-spacing: 0.035px;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 100%;
      .selected-value{
        color: var(--colorTextPrimary);
      }
    }
    .expand-more-icon{
      transition: all 0.3s;
      transform: rotate(0);
      &.active{
        transform: rotate(-180deg);
      }
    }
    .image-selected-wrapper{
      min-width: 20px;
      max-width: 20px;
      min-height: 20px;
      max-height: 20px;
      width: 20px;
      height: 20px;
      display: flex;
      .image-selected-element{
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  .default-list-dropdown__options{
    padding: 4px 0;
    border-radius: 8px;
    border: 0.5px solid var(--colorStrokeSecondary);
    background-color: var(--colorComponentDropdownListItemBackground);
    box-shadow: 0px 0px 8px 0px rgba(30, 30, 36, 0.10), 0px 8px 16px 0px rgba(23, 23, 28, 0.05);
    text-transform: none;
    position: absolute;
    z-index: 1000;
    top:calc(100% + 5px);
    left: 0;
    width: 100%;
    min-width: min-content;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .default-list-dropdown__options_search{
      padding: 8px 12px;
      .search-container{
        padding: 8px 12px;
        display: flex;
        align-items: center;
        gap: 8px;
        border-radius: 8px;
        background: var(--colorComponentFieldBackground);
        .dropdown-active-search-icon{
          min-height: 20px;
          max-height: 20px;
          min-width: 20px;
          max-width: 20px;
          svg{
            display: block;
            width: 100%;
            height: 100%;
            max-width: 100%;
            max-height: 100%;
          }
        }
        .dropdown-active-search-input{
          width: 100%;
          input{
            border:0;
            height: 20px;
            outline: none;
            width: 100%;
            color: var(--colorTextSecondary);
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 125% */
            letter-spacing: 0.016px;
            background-color: transparent;
          }
        }
      }
    }
    .default-list-dropdown__options_list{
      .wrapper-list-container{
        height: max-content;
        max-height: 185px;
        overflow-y: auto;
        overflow-x:hidden;
        &::-webkit-scrollbar{
          width: 5px;
        }
        &::-webkit-scrollbar-track{
          background: transparent;
        }
        &::-webkit-scrollbar-thumb{
          border-radius: 8px;
          background: var(--colorComponentScrollbarKnob);
        }
        .options-element{
          padding: 10px 19px 10px 20px;
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          gap: 8px;
          width: 100%;
          color: var(--colorTextPrimary);
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 125% */
          letter-spacing: 0.016px;
          .image-option-wrapper{
            min-width: 24px;
            max-width: 24px;
            min-height: 24px;
            max-height: 24px;
            width: 24px;
            height: 24px;
            display: flex;
            .image-option-element{
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
          //word-break: break-all;
          .options-element__text{
            width: 100%;
          }
          .options-element__icon{
            display: none;
            svg{
              display: block;
              width: 100%;
              height: 100%;
              max-width: 100%;
              max-height: 100%;
            }
          }
          &:hover{
            background: var(--colorComponentDropdownListItemBackgroundHover);
          }
        }
        .options-element.active{
          .options-element__icon{
            display: block;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1365px){
  .default-list-dropdown{
    @include mobileView;
  }
}
</style>
