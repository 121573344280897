<script>
import FunctionsTable from "@/components/redesign/Table/FunctionsTable.vue";
import TableComponent from "@/components/redesign/Table/tableComponent.vue";
import OptionsRow from "@/components/redesign/Table/OptionsRow.vue";
import axios from "axios";
import moment from "moment/moment";
import priceFormat from "../../../mixins/priceFormat";
import TableRowChips from "@/components/redesign/Table/tableRowChips.vue";
import AlertModal from "@/components/redesign/alertModal.vue";

export default {
  name: "StockLayout",
  mixins: [priceFormat],
  components: {AlertModal, TableRowChips, OptionsRow, TableComponent, FunctionsTable},
  data() {
    return {
      tabs: [
        {
          value: this.$i18n.t('Актуальное'),
          id: 'active',
          icon: '/default-icons/task.svg',
          action: 'archive'
        },
        {
          value: this.$i18n.t('Архив'),
          id: 'archive',
          icon: '/default-icons/history.svg',
          action: 'active'
        }
      ],
      activeTab: {},
      searchQuery: null,
      stockList: [],
      visibleConfirmArchiveStock: false,
      stock: null
    }
  },
  watch: {
    activeTab: {
      handler(after) {
        this.getStocks()
      },
      deep: true
    }
  },
  methods: {
    async getStocks() {
      const res = await axios.get('calculator/stocks/get-all?mode=' + this.activeTab.id)
          .then(res => res.data)
          .catch(e => [])

      this.stockList = res
    },
    toCreateStock() {
      this.$router.push({name: 'create-stock'})
    },
    editStock(stock) {
      this.$router.push({name: 'edit-stock', params: {id: stock.id}})
    },
    async actionStock() {
      const res = await axios.get(`calculator/stocks/${this.activeTab.action}?id=` + this.stock.id)
          .then(res => res.data)
          .catch(e => {})

      this.visibleConfirmArchiveStock= false
      this.getStocks()
    },
    confirmActionStock(stock) {
      this.stock = stock

      if(this.activeTab.id === 'archive') {
        this.actionStock()
        return;
      }

      this.visibleConfirmArchiveStock = true
    },
    dateFormat (value) {
      return moment(value).format('DD.MM.YYYY')
    },
    getChipsByDevelopments(stock) {
      return stock.developments.map(el => el.name + ' - ' + el.house)
    },
    getTypesObjectsValue(stock) {
      return stock.types_objects.map(el => el.value)
    },
    getProgramsValue(stock) {
      return stock.programs.map(el => el.name)
    },
  },
  async mounted() {
    await this.getStocks()
  }
}
</script>

<template>
  <div class="stock-layout">
    <alert-modal
        v-if="visibleConfirmArchiveStock"
        :centralText="true"
        :title="$t('ОтключениеАкции')"
        description="Вы уверены что хотите отключить эту акцию?"
        :confirmText="$t('Отключить')"
        :visibleCancelBottomButton="true"
        @confirm-alert="actionStock"
        @cancel-alert="visibleConfirmArchiveStock = false"
    />
    <functions-table
        :visible-settings-table="false"
        @update:activeTab="(value) => this.activeTab = value"
        @update:searchQuery="(value) => this.searchQuery = value"
        :tabs="tabs"
        @add-row="toCreateStock"
    ></functions-table>
    <table-component
        v-model="stockList"
        :colspan="6"
        :visible-title="false"
    >
      <template #header-table>
        <th>Название</th>
        <th>Сроки проведения</th>
        <th>Скидка</th>
        <th>Проекты</th>
        <th>Тип объекта</th>
        <th>Программы</th>
        <th></th>
      </template>
      <template #content-table="{el}">
        <td>{{el.name}}</td>
        <td>
          <template v-if="el.date_start">{{dateFormat(el.date_start)}}</template>
          <template v-if="el.date_end"> - {{dateFormat(el.date_end)}}</template>
        </td>
        <td>
          <template v-if="el.sum_square">{{priceFormat(el.sum_square)}}/{{$t('м²')}}</template>
          <template v-if="el.percent_square">{{el.percent_square}}%/{{$t('м²')}}</template>
          <template v-if="el.sum_all">{{priceFormat(el.sum_all)}}</template>
          <template v-if="el.percent_all">{{el.percent_all}}%</template>
        </td>
        <td>
          <table-row-chips
              :chips="getChipsByDevelopments(el)"
          />
        </td>
        <td>
          <table-row-chips
              :chips="getTypesObjectsValue(el)"
          />
        </td>
        <td>
          <table-row-chips
              :chips="getProgramsValue(el)"
          />
        </td>
        <td class="options">
          <options-row
              :activeTab="activeTab?.id"
              @read-more="editStock(el)"
              @archive="confirmActionStock(el)"
          />
        </td>
      </template>
    </table-component>
  </div>
</template>

<style scoped lang="scss">
.stock-layout {

}
</style>