<script>
import dropdownComponent from "@/components/dropdown.vue";
import ModalContainer from "@/components/redesign/modalContainer.vue";
import priceFormat from "@/mixins/priceFormat";
import OptionsRow from "@/components/redesign/Table/OptionsRow.vue";
import DefaultButton from "@/components/redesign/defaultButton.vue";
import FunctionsTable from "@/components/redesign/Table/FunctionsTable.vue";
import IconComponent from "@/components/redesign/iconComponent.vue";
import TableComponent from "@/components/redesign/Table/tableComponent.vue";
import axios from "axios";
import TableRowChips from "@/components/redesign/Table/tableRowChips.vue";
import AlertModal from "@/components/redesign/alertModal.vue";

export default {
  name: "MarkupsLayout",
  components: {
    AlertModal,
    TableRowChips,
    TableComponent,
    IconComponent,
    FunctionsTable,
    DefaultButton,
    OptionsRow,
    ModalContainer, dropdownComponent},
  mixins: [priceFormat],
  data() {
    return {
      markup: null,
      visibleConfirmArchive: false,
      markups: [],
      searchQuery: null,
      activeTab: {},
      tabs: [
        {
          value: this.$i18n.t('Актуальное'),
          id: 'active',
          icon: '/default-icons/task.svg',
          action: 'archive'
        },
        {
          value: this.$i18n.t('Архив'),
          id: 'archive',
          icon: '/default-icons/history.svg',
          action: 'active'
        }
      ],
    }
  },
  watch: {
    activeTab: {
      handler(after) {
        this.getMarkups()
      },
      deep: true
    }
  },
  methods:{
    getChipsByDevelopments(el) {
      return el.map(el => el.name + ' - ' + el.house)
    },
    getName(el) {
      return (el ?? []).map(el => el.name)
    },
    getValue(el) {
      return (el ?? []).map(el => el.value)
    },
    async getMarkups() {
      const res = await axios.get('calculator/markups/get-all?mode=' + this.activeTab.id)
          .then(res => res.data)
          .catch(e => [])

      const newArr = (res ?? []).reduce((acc, el) => {
        if(!acc[el.type]) {
          acc = {
            ...acc,
            [el.type]: {
              name: el.type,
              children: []
            }
          }
        }
        acc[el.type]['children'].push(el)
        return acc
      }, {})
      this.markups = Object.values(newArr)
    },
    addMarkup() {
      this.$router.push({name: 'create-markups'})
    },
    editMarkup(markup) {
      this.$router.push({name: 'edit-markups', params: {
        id:markup.id
      }})
    },
    async action() {
      const res = await axios.get(`calculator/markups/${this.activeTab.action}?id=` + this.markup.id)
          .then(res => res.data)
          .catch(e => {})

      this.visibleConfirmArchive= false
      this.getMarkups()
    },
    confirmAction(markup) {
      this.markup = markup
      if(this.activeTab.id === 'archive') {
        this.action()
        return;
      }

      this.visibleConfirmArchive = true
    },
  },
  mounted() {
    this.activeTab = this.tabs[0]
    this.getMarkups()
  }
}
</script>

<template>
  <div class="markups-calc">
    <alert-modal
        v-if="visibleConfirmArchive"
        :centralText="true"
        :title="$t('ОтключениеАкции')"
        description="Вы уверены что хотите отключить эту акцию?"
        :confirmText="$t('Отключить')"
        :visibleCancelBottomButton="true"
        @confirm-alert="action"
        @cancel-alert="visibleConfirmArchive = false"
    />
    <functions-table
        :tabs="tabs"
        @update:activeTab="(value) => activeTab = value"
        @update:searchQuery="(value) => searchQuery = value"
        @add-row="addMarkup"
    >
    </functions-table>
    <table-component
        v-model="markups"
        :colspan="9"
    >
      <template #header-table>
        <th>{{ $t('ТипОбъекта') }}</th>
        <th>{{ $t('Строение') }}</th>
        <th>{{ $t('Секция') }}</th>
        <th>{{ $t('ось') }}</th>
        <th>{{ $t('Этаж') }}</th>
        <th>{{ $t('Комнатность') }}</th>
        <th>{{ $t('ПлощадьОбъекта') }}</th>
        <th>{{ $t('СтроимостьОбъекта') }}</th>
        <th>{{ $t('Программы') }}</th>
      </template>
      <template #title-options="{ el }">
        <default-button
            @click-element=""
            type="outline"
            size="medium"
            leftIcon="/default-icons/info_fill.svg"
        />
      </template>
      <template #content-table-title="{ el }">
        {{el.name}}
      </template>
      <template #content-table-child="{child}">
        <td>
          <table-row-chips
              :chips="getValue(child.types_objects)"
          />
        </td>
        <td>
          <table-row-chips
              :chips="getName(child.developments)"
          />
        </td>
        <td>
          <table-row-chips
              :chips="child.entrances"
          />
        </td>
        <td>
          <table-row-chips
              :chips="child.axis"
          />
        </td>
        <td>
          <table-row-chips
              :chips="child.floors"
          />
        </td>
        <td>
          <table-row-chips
              :chips="child.rooms"
          />
        </td>
        <td>
          <template v-if="child.square_object_min != null">{{child.square_object_min}} м²</template>
          <template v-if="child.square_object_max != null"> - {{child.square_object_max}} м²</template>
        </td>
        <td>
          <template v-if="child.cost_object_min != null">{{priceFormat(child.cost_object_min)}} </template>
          <template v-if="child.cost_object_max != null"> - {{priceFormat(child.cost_object_max)}}</template>
        </td>
          <td>
            <table-row-chips
                :chips="getName(child.programs)"
            />
          </td>
        <td class="options">
          <options-row
              :activeTab="activeTab?.id"
              @read-more="editMarkup(child)"
              @archive="confirmAction(child)"
          />
        </td>
      </template>
    </table-component>
  </div>
</template>

<style scoped lang="scss">
.markups-calc{

}
</style>