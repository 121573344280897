<template>
  <div class="default-double-range">
    <div class="default-double-range__block">
      <div :class="'default-double-range__block_half '+(focused_1?'active':'')">
        <div class="block-half-input">

          <input
              @focus="focused_1 = true" @blur="focused_1 = false"
              class="tools__input_text"
              type="text" placeholder=""
              @input="fixValueMin"
              v-model="minValue"
          >
        </div>
      </div>
      <div class="default-double-range__block_separator">
        <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M16 0.386364V1.63636H0V0.386364H16Z" fill="#9299A2"/>
        </svg>
      </div>
      <div :class="'default-double-range__block_half '+(focused_2?'active':'')">
        <div class="block-half-input">
          <input
              @focus="focused_2 = true" @blur="focused_2 = false"
              class="tools__input_text"
              type="text" placeholder=""
              @input="fixValueMax"
              v-model="maxValue"
          >
        </div>
<!--        <div class="block-half-unit">{{unit}}</div>-->
      </div>
    </div>
    <vue-slider
        :disabled="disabledSlider"
        v-if="currentValues.length&&!mobile"
        :enable-cross="currentValues[0]!==currentValues[1]?false:''"
        :class="'double-components-range '+(disabledSlider?'disabled':'')"
        v-model="currentValues"
        :interval="doubleType?0.01:1"
        :min="minOpt"
        :max="options.max"
        dot-size="25"
        :tooltip="'none'"
        :process-style="{ backgroundColor:  'var(--colorBackgroundAccentExtraInvariably)' }"
        :tooltip-style="{ backgroundColor: 'var(--colorBackgroundAccentExtraInvariably)', borderColor: 'var(--colorBackgroundAccentExtraInvariably)'  }"
    >
      <template v-slot:dot="{ focus }">
        <div :class="['custom-dot-range', { focus }]"></div>
      </template>
    </vue-slider>
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component'
import AutosizeInput from 'vue-autosize-input'
export default {
  components: {AutosizeInput,VueSlider},
  name: "defaultDoubleRange",
  model: {
    prop: 'selected',
    event: 'select-deal'
  },
  props:{
    minOpt:{
      type: Number,
      default:0
    },
    unit:{
      type:String,
      default:''
    },
    disabledSlider:{
      type:Boolean,
      default:false
    },
    mobile:{
      type:Boolean,
      default:false
    },
    doubleType:{
      type:Boolean,
      default:false
    },
    selected:{
      type:Object,
      default: () => {}
    },
    options:{
      type:Object,
      default: () => {}
    },
  },
  data(){
    return {
      sel:0,
      clearProcess:false,
      fixedValueMin:null,
      fixedValueMax:null,
      focused_1:false,
      focused_2:false,
      temporaryValueMin:0,
      temporaryValueMax:0,
      selectStart:0,
      selectEnd:400,
      areOptionsVisible:false,
      userIds: [],
      currentValues:[],
      timer1:null,
      timer2:null,
      timer3:null,
      timer4:null,
    }
  },
  watch:{
    selected: {
      handler: function (after, before) {
        if(this.currentValues[0]!==after.min||this.currentValues[1]!==after.max){
          this.clearProcess=true
          this.currentValues=[after.min,after.max]
        }

      },
      deep: true
    },
    currentValues: {
      handler: function (after, before) {
        this.temporaryValueMin=after[0]
        this.temporaryValueMax=after[1]

        if(after[0]==this.minOpt&&this.fixedValueMin==''&&this.mobile){}
        else if(after[0]!==this.fixedValueMin){this.fixedValueMin=after[0]}

        if(after[1]==this.minOpt&&this.fixedValueMax==''&&this.mobile){}
        else if(after[1]!==this.fixedValueMax){this.fixedValueMax=after[1]}

        if(!this.clearProcess){
          if(!this.mobile){
            if (this.timer3 !== null) {
              clearTimeout(this.timer3)
            }
            this.timer3 = setTimeout(() => {
              this.closeSelect()
            },300)
          } else this.closeSelect()
        } else this.clearProcess=false
      },
      deep: true
    },
  },
  computed:{
    minValue: {
      // getter
      get: function() {
        if (this.fixedValueMin !== null) {
          return this.formatUSD(this.fixedValueMin);
        }
      },
      // setter
      set: function(newValue) {

      }
    },
    maxValue: {
      // getter
      get: function() {
        if (this.fixedValueMax !== null) {
          return this.formatUSD(this.fixedValueMax);
        }
      },
      // setter
      set: function(newValue) {

      }
    },
  },
  methods:{
    fixValueMin(event){
      if (this.timer1 !== null) {
        clearTimeout(this.timer1)
      }
      if (this.timer2 !== null) {
        clearTimeout(this.timer2)
      }
      if (this.timer3 !== null) {
        clearTimeout(this.timer3)
      }
      if (this.timer4 !== null) {
        clearTimeout(this.timer4)
      }
      let newValue= event.target.value
      if(!isNaN(this.parseUSD(newValue))&&this.parseUSD(newValue)!==false){
        if(String(newValue).indexOf('.')!==String(newValue).length-1&&String(newValue).indexOf(',')!==String(newValue).length-1&&String(newValue).length){
          this.fixedValueMin=this.parseUSD(newValue)
          this.$forceUpdate()
          if(!this.mobile){
            this.timer1 = setTimeout(() => {
              let result=this.parseUSD(newValue)
              if (result>this.options.max){
                result=this.options.max
              }else if(result<this.minOpt){
                result=this.minOpt
              }
              this.temporaryValueMin = result;
              if (result>this.temporaryValueMax){
                this.currentValues=[result,result]
                this.temporaryValueMax=result
              } else {
                this.currentValues=[result,this.currentValues[1]]
              }
            }, 300)
          }else {
            let result=this.parseUSD(newValue)
            if (result>this.options.max){
              result=this.options.max
            }else if(result<this.minOpt){
              result=this.minOpt
            }
            this.temporaryValueMin = result;
            if (result>this.temporaryValueMax){
              this.currentValues=[result,result]
              this.temporaryValueMax=result
            } else {
              this.currentValues=[result,this.currentValues[1]]
            }
          }
        } else{
          if(String(newValue).length==0){
            this.fixedValueMin=''
            this.$forceUpdate()
            if(!this.mobile){
              this.timer1 = setTimeout(() => {
                let result=this.minOpt
                this.temporaryValueMin = result;
                if (result>this.temporaryValueMax){
                  this.currentValues=[result,result]
                  this.temporaryValueMax=result
                } else {
                  this.currentValues=[result,this.currentValues[1]]
                }
              }, 300)
            } else {
              let result=this.minOpt
              this.temporaryValueMin = result;
              if (result>this.temporaryValueMax){
                this.currentValues=[result,result]
                this.temporaryValueMax=result
              } else {
                this.currentValues=[result,this.currentValues[1]]
              }
            }
          }
        }
      } else {
        if(newValue!=='-'&&this.parseUSD(newValue)!==false){
          this.fixedValueMax=''
          this.$forceUpdate()
        }else if(this.parseUSD(newValue)===false){
          let text=String(newValue)
          text=text.replace(/,/g, ".").replace(/\s+/g, '')
          let result=parseFloat(text)

          if (result>this.options.max){
            result=this.options.max
          }else if(result<this.minOpt){
            result=this.minOpt
          }
          if(!this.mobile){
            this.timer4 = setTimeout(()=>{
              this.temporaryValueMin = result;
              if (result>this.temporaryValueMax){
                this.currentValues=[result,result]
                this.temporaryValueMax=result
              } else {
                this.currentValues=[result,this.currentValues[1]]
              }
              this.$forceUpdate()
            },300)
          } else {
            this.closeSelect()
          }
        }
      }
    },
    fixValueMax(event){
      if (this.timer1 !== null) {
        clearTimeout(this.timer1)
      }
      if (this.timer2 !== null) {
        clearTimeout(this.timer2)
      }
      if (this.timer3 !== null) {
        clearTimeout(this.timer3)
      }
      if (this.timer4 !== null) {
        clearTimeout(this.timer4)
      }
      let newValue= event.target.value
      if(!isNaN(this.parseUSD(newValue))&&this.parseUSD(newValue)!==false){
        if(String(newValue).indexOf('.')!==String(newValue).length-1&&String(newValue).indexOf(',')!==String(newValue).length-1&&String(newValue).length){
          this.fixedValueMax=this.parseUSD(newValue)
          this.$forceUpdate()
          if(!this.mobile){
            this.timer2 = setTimeout(() => {
              let result=this.parseUSD(newValue)
              if (result>this.options.max){
                result=this.options.max
              }else if(result<this.minOpt){
                result=this.minOpt
              }
              this.temporaryValueMax = result;
              if (result<this.temporaryValueMin){
                this.currentValues=[result,result]
                this.temporaryValueMin=result
              } else {
                this.currentValues=[this.currentValues[0],result]
              }
              this.$forceUpdate()
            }, 300)
          } else {
            let result=this.parseUSD(newValue)
            if (result>this.options.max){
              result=this.options.max
            }else if(result<this.minOpt){
              result=this.minOpt
            }
            this.temporaryValueMax = result;
            if (result<this.temporaryValueMin){
              this.currentValues=[result,result]
              this.temporaryValueMin=result
            } else {
              this.currentValues=[this.currentValues[0],result]
            }
            this.$forceUpdate()
          }
        } else{
          if(String(newValue).length==0){
            this.fixedValueMax=''
            this.$forceUpdate()
            if(!this.mobile){
              this.timer2 = setTimeout(() => {
                let result=this.minOpt
                this.temporaryValueMax = result;
                if (result<this.temporaryValueMin){
                  this.currentValues=[result,result]
                  this.temporaryValueMin=result
                } else {
                  this.currentValues=[this.currentValues[0],result]
                }
                this.$forceUpdate()
              }, 300)
            } else {
              let result=this.minOpt
              this.temporaryValueMax = result;
              if (result<this.temporaryValueMin){
                this.currentValues=[result,result]
                this.temporaryValueMin=result
              } else {
                this.currentValues=[this.currentValues[0],result]
              }
              this.$forceUpdate()
            }
          }
        }
      } else {
        if(newValue!=='-'&&this.parseUSD(newValue)!==false){
          this.fixedValueMax=''
          this.$forceUpdate()
        }else if(this.parseUSD(newValue)===false){
          let text=String(newValue)
          text=text.replace(/,/g, ".").replace(/\s+/g, '')
          let result=parseFloat(text)
          if (result>this.options.max){
            result=this.options.max
          }else if(result<this.minOpt){
            result=this.minOpt
          }
          if(!this.mobile){
            this.timer4 = setTimeout(()=>{
              this.temporaryValueMax = result;
              if (result<this.temporaryValueMin){
                this.currentValues=[result,result]
                this.temporaryValueMin=result
              } else {
                this.currentValues=[this.currentValues[0],result]
              }
              this.$forceUpdate()
            },300)
          } else {
            this.closeSelect()
          }
        }
      }
    },
    parseUSD(text) {
      if(text!==''){
        text=text.replace(/,/g, ".").replace(/\s+/g, '')
        const counter = this.counterSymbols(text)
        if(counter&&text[text.length-1]==0){
          if(counter>2)return parseFloat(text)
          else return false
        } else return parseFloat(text);
      } else return ''
    },
    formatUSD(num) {
      if(num){
        if(String(num).indexOf('.')!==-1){
          return this.$root.numberWithSpaces(Number(Number(num).toFixed(2))).replace(/,/g, ".");
        } else return this.$root.numberWithSpaces(Number(num));
      } else if(num===0) return 0
      else return
    },
    counterSymbols(text){
      return (text.toString().includes('.')) ? (text.toString().split('.').pop().length) : (0)
    },
    priceFormat(value) {
      return value.toLocaleString()
    },
    closeSelect(){
      if(!this.temporaryValueMin)this.temporaryValueMin=0
      if(!this.temporaryValueMax)this.temporaryValueMax=0
      let option=JSON.parse(JSON.stringify({'min':this.parseUSD(this.formatUSD(String(this.temporaryValueMin))), 'max':this.parseUSD(this.formatUSD(String(this.temporaryValueMax)))}))
      this.$emit('select-deal',option)
      this.areOptionsVisible=false;
    }
  },
  mounted() {
    if(!this.selected?.min || !this.selected?.max) {
      this.currentValues=[this.options.min,this.options.max]
    }
    else {
      this.currentValues=[this.selected.min,this.selected.max]
    }

  }

}
</script>

<style lang="scss" scoped>
@mixin mobileView(){
  input{
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 125% */
    letter-spacing: 0.016px;
  }
}
.default-double-range{
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  .default-double-range__block{
    border-radius: 8px;
    background: var(--colorComponentFieldBackground);
    display: flex;
    border: 0.5px solid var(--colorComponentFieldStroke);
    .default-double-range__block_half{
      border: 1px solid transparent;
      padding: 6px 12px;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      gap: 5px;
      border-radius: 8px;
      width: 100%;
      &:hover{
        background: var(--colorComponentFieldBackgroundHover);
      }
      .block-half-input{
        display: inline-block;
        input{
          box-sizing: content-box;
          width: 100%;
          border: 0;
          outline: none;
          background-color: transparent;
          padding: 0;

          overflow: hidden;
          color: var(--colorTextSecondary);

          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 125% */
          letter-spacing: 0.016px;
        }
      }
      .block-half-unit{
        color: #9299A2;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
    }
    .default-double-range__block_half.active{
      background: var(--colorComponentFieldBackgroundActive);
      border: 0.5px solid var(--colorStrokeAccentExtraInvariably);
      //border: 1px solid #9299A2;
    }
    .default-double-range__block_separator{
      display: flex;
      align-items: center;
    }
  }
  .double-components-range{
    padding: 8px 16px !important;
    /*position: absolute;
    bottom: -7px;
    left: 15px;
    right: 15px;*/
    border-radius: 100px;
    outline: none;
    .custom-dot-range{
      width: 100%;
      height: 100%;
      background-color: #6F77FE;
      border-radius: 50%;
    }
    &:deep(.vue-slider-rail) {
      border-radius: 8px;
      background-color: var(--colorIconTertiary) !important;
    }
    &.disabled{
      .custom-dot-range{
        width: 0;
        height: 0;
      }
    }
  }
}
@media screen and (max-width: 1365px){
  .default-double-range{
    .default-double-range__block {
      .default-double-range__block_half {
        padding: 12px 16px;
        .block-half-input {
          @include mobileView;
        }
      }
    }
  }
}
</style>